import React from "react";

const Vastus = () => {
  return (
    <div>
      <svg
        version="1.0"
        fill="#F37B35"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="80px"
        height="80px"
        viewBox="0 0 64 64"
        enableBackground="new 0 0 64 64"
        xmlSpace="preserve"
      >
        <g>
          <path
            
            d="M21.839,42.16l14.396-5.927l5.927-14.396l-14.396,5.927L21.839,42.16z M32,28.999c1.656,0,3,1.344,3,3
		s-1.344,3-3,3s-3-1.344-3-3S30.344,28.999,32,28.999z"
          />
          <circle  cx="32" cy="31.999" r="1" />
          <path
            class="cls-1"
            
            d="M32,0C14.327,0,0,14.327,0,32s14.327,32,32,32s32-14.327,32-32S49.673,0,32,0z M32,59.999
		c-15.464,0-28-12.536-28-28s12.536-28,28-28s28,12.536,28,28S47.464,59.999,32,59.999z"
          />
          <path
            class="cls-1"
            
            d="M52,31.999c0-0.553,0.447-1,1-1h4.975c-0.243-6.425-2.815-12.252-6.899-16.661l-1.397,1.397
		c-0.391,0.392-1.023,0.391-1.414,0s-0.391-1.023,0-1.414l1.397-1.397C45.252,8.84,39.425,6.268,33,6.024v4.975c0,0.553-0.447,1-1,1
		s-1-0.447-1-1V6.024c-6.425,0.243-12.252,2.815-16.661,6.899l1.397,1.397c0.392,0.391,0.391,1.023,0,1.414s-1.023,0.391-1.414,0
		l-1.397-1.397c-4.084,4.409-6.656,10.236-6.899,16.661H11c0.553,0,1,0.447,1,1s-0.447,1-1,1H6.025
		c0.243,6.425,2.815,12.252,6.899,16.661l1.397-1.397c0.391-0.392,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-1.397,1.397
		C18.748,55.158,24.575,57.73,31,57.974v-4.975c0-0.553,0.447-1,1-1s1,0.447,1,1v4.975c6.425-0.243,12.252-2.815,16.661-6.899
		l-1.397-1.397c-0.392-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0l1.397,1.397c4.084-4.409,6.656-10.236,6.899-16.661H53
		C52.447,32.999,52,32.552,52,31.999z M44.925,20.38l-7,17c-0.102,0.246-0.298,0.442-0.544,0.544l-17,7
		c-0.123,0.051-0.253,0.075-0.381,0.075c-0.26,0-0.516-0.102-0.707-0.293c-0.285-0.285-0.371-0.715-0.218-1.088l7-17
		c0.102-0.246,0.298-0.442,0.544-0.544l17-7c0.373-0.152,0.803-0.067,1.088,0.218S45.078,20.007,44.925,20.38z"
          />
        </g>
      </svg>
    </div>
  );
};

export default Vastus;
