import React from "react";
import { Link } from "react-router-dom";

const Slider = () => {
  return (
    <div>
      <section class="as_banner_wrapper">
        <div class="container">
          <div class="row as_verticle_center">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="as_banner_slider">
            
             
              </div>
           <div className="btns">
           <Link to="/contact?Appointment=true" class="as_btn">
                    Appointment
                  </Link>
           </div>
            </div>
            {/* <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="as_banner_img text-center">
                <img
                  src="assets/images/hand_bg.png"
                  alt=""
                  class="img-responsive as_hand_bg"
                />
                <img
                  src="assets/images/hand.png"
                  alt=""
                  class="img-responsive as_hand"
                />
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Slider;
