import React from "react";
import Arch from "../pages/Services/Svg/Arch";
import Int from "../pages/Services/Svg/Int";
import Vastus from "../pages/Services/Svg/Vastus";
import ShopSvg from "../pages/Services/Svg/ShopSvg";
import { Link } from "react-router-dom";

const Cards = () => {
  const data = [
    {
      id: 1,
      Btntitle: "Architecture Consultation",
      image: <Arch />,
      borderColor: "border-top border-danger", // Bootstrap classes for top red border
      href: "Architecture",
    },
    {
      id: 2,
      Btntitle: "Interior Design Consultation",
      image: <Int />,
      borderColor: "border-top border-success", // Bootstrap classes for top green border
      href: "Interior",
    },
    {
      id: 3,
      Btntitle: "Vastu Consultation",
      image: <Vastus />,
      borderColor: "border-top border-primary", // Bootstrap classes for top blue border
      href: "Vastu",
    },
    {
      id: 4,
      Btntitle: "Construction mall",
      image: <ShopSvg />,
      borderColor: "border-top border-warning", // Bootstrap classes for top yellow border
      href: "Construction-mall",
    },
  ];

  return (
    <div>
      <section className="as_blog_wrapper py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 text-center">
              <div className="v3_blog_wrapper">
                <div className="row  text-left">
                  {data.map((post) => (
                    <div key={post.id} className="col-lg-3  col-md-6 col-sm-6 col-12 mb-4">
                      <div className={`card  CSS p-4  h-100`}>
                        <div className="card-body text-center">
                          <div className="mb-3">
                            <Link className="kls" style={{color:'orange'}} to={`/${post.href}`}>{post.image}</Link>
                          </div>
                          <h5 className="card-title">
                            <Link to={`/${post.href}`} className="text-decoration-none">
                              {post.Btntitle}
                            </Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Cards;
