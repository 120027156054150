import React from 'react'

const WhyChooseUs = () => {
    return (
        <div>
            <section class="as_whychoose_wrapper">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 text-center">
                            <h1 class="as_heading">Why Choose <span className='about_color'>Help U Build</span></h1>

                            <p class="as_font14 as_padderTop20 as_padderBottom50">**Why Choose Help U Build**

                                Choose Help U Build for expert architecture, interior design, and Vastu solutions, blending modern innovation with traditional wisdom.</p>
                        </div>
                        <div class="col-lg-12">
                            <div class="row mx-auto">
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-4">
                                    <div class="as_whychoose_box text-center">
                                        <span class="as_number">
                                            <img src="https://i.ibb.co/BByqbSw/stamp.png" alt="" />
                                        </span>
                                        <h4 className='text-white'>Verified Professionals</h4>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 mb-4">
                                    <div class="as_whychoose_box text-center">
                                        <span class="as_number">
                                            <img src="https://i.ibb.co/FJZQGFh/price-tag.png" alt="" />
                                        </span>
                                        <h4 className='text-white'>Transparent Pricing</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default WhyChooseUs
