import React from 'react'
import Slider from '../../components/Slider'
import About from '../About/About'
import Services from '../Services/Services'
import WhyChooseUs from '../../components/WhyChooseUs'
import Reviews from '../../components/Reviews'
import Blog from '../../components/Blog'
import Cards from '../../components/Cards'

const Home = () => {
  return (
    <div>
        <Slider/>
        <Cards/>
        <About/>
        <Services/>
        <WhyChooseUs/>
        <Reviews/>
        <Blog/>
    </div>
  )
}

export default Home
