import React from "react";

const Arch = () => {
  return (
    <div>
      <svg
        fill="#000000"
        width="80"
        height="80"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 295.239 295.239"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <g>
              <path
               class="cls-1"
                d="M244.352,239.382l-6.91-6.557c-0.348,0.367-0.729,0.695-1.081,1.057l-39.614-129.643
				c5.005-6.448,8.014-14.514,8.014-23.286c0-21.005-17.09-38.095-38.095-38.095V25.372c1.267,0.195,2.524,0.329,3.79,0.562
				l1.738-9.367c-1.957-0.362-3.91-0.595-5.867-0.867c-1.59-8.905-9.352-15.7-18.71-15.7c-9.358,0-17.117,6.796-18.707,15.701
				c-1.957,0.276-3.91,0.505-5.867,0.867l1.738,9.367c1.267-0.233,2.524-0.367,3.79-0.562v17.486
				c-21.005,0-38.095,17.09-38.095,38.095c0,8.771,3.01,16.838,8.01,23.281L58.871,233.877c-0.352-0.362-0.733-0.69-1.081-1.057
				l-6.91,6.557c1.557,1.638,3.214,3.19,4.857,4.757l-7.914,25.905l-0.205,25.2l27.91-20.933l3.757-12.295
				c1.014,0.61,2.005,1.281,3.024,1.852l4.662-8.305c-1.624-0.914-3.214-1.952-4.819-2.952l17.533-57.367h95.862l17.529,57.371
				c-1.605,1-3.19,2.038-4.819,2.952l4.662,8.305c1.024-0.576,2.01-1.248,3.024-1.852l3.757,12.295l27.919,20.929V270.72
				l-8.124-26.581C241.138,242.572,242.8,241.02,244.352,239.382z M166.667,52.382c15.757,0,28.571,12.814,28.571,28.571
				c0,9.171-4.362,17.329-11.1,22.557c-2.79-9.433-9.138-17.333-17.49-22.176c0-0.129,0.019-0.252,0.019-0.381
				c0-10.276-4.11-19.595-10.748-26.457C159.243,53.144,162.867,52.382,166.667,52.382z M165.086,91.73
				c5.181,4.019,8.943,9.767,10.41,16.381c-2.782,0.909-5.744,1.414-8.829,1.414c-3.8,0-7.424-0.762-10.748-2.114
				C160.138,103.049,163.324,97.697,165.086,91.73z M147.619,102.191c-4.362-3.919-7.524-9.138-8.833-15.062
				c2.785-0.909,5.747-1.414,8.833-1.414s6.048,0.505,8.833,1.414C155.142,93.053,151.981,98.272,147.619,102.191z M147.619,76.191
				c-3.205,0-6.3,0.443-9.276,1.19c0.876-6.985,4.286-13.18,9.276-17.666c4.99,4.481,8.4,10.676,9.276,17.662
				C153.919,76.634,150.824,76.191,147.619,76.191z M139.319,107.411c-3.324,1.352-6.948,2.114-10.748,2.114
				c-3.086,0-6.048-0.505-8.833-1.414c1.467-6.614,5.229-12.362,10.41-16.381C131.914,97.696,135.1,103.049,139.319,107.411z
				 M128.571,119.048c6.943,0,13.438-1.895,19.048-5.152c5.61,3.257,12.105,5.152,19.048,5.152c3.205,0,6.3-0.443,9.276-1.19
				c-1.767,14.072-13.781,25-28.324,25c-14.543,0-26.557-10.929-28.324-25C122.271,118.606,125.366,119.048,128.571,119.048z
				 M138.095,19.049c0-5.252,4.271-9.524,9.524-9.524s9.524,4.271,9.524,9.524v25.062c-3.381,0.876-6.576,2.19-9.524,3.9
				c-2.948-1.714-6.143-3.024-9.524-3.9V19.049z M128.571,52.382c3.8,0,7.424,0.762,10.748,2.114
				c-6.638,6.862-10.748,16.181-10.748,26.457c0,0.129,0.019,0.252,0.019,0.381c-8.352,4.843-14.7,12.743-17.49,22.176
				c-6.738-5.229-11.1-13.386-11.1-22.557C100,65.196,112.814,52.382,128.571,52.382z M67.329,268.549l-10.186,7.642v-4.052
				l49.024-160.448c1.086,0.795,2.21,1.533,3.376,2.21c0,0.133-0.019,0.257-0.019,0.386c0,3.79,0.576,7.443,1.61,10.9
				L67.329,268.549z M102.6,185.715l14.776-48.357c6.967,9.11,17.914,15.024,30.243,15.024c12.329,0,23.276-5.914,30.243-15.024
				l14.776,48.357H102.6z M238.096,276.191L238.096,276.191l-10.187-7.638l-43.804-143.362c1.033-3.462,1.61-7.114,1.61-10.905
				c0-0.129-0.019-0.252-0.019-0.381c1.167-0.676,2.29-1.419,3.376-2.21l49.024,160.443V276.191z"
              />
              <path d="M208.3,28.882c-5.633-2.876-11.529-5.381-17.514-7.433l-3.081,9.014c5.557,1.9,11.029,4.224,16.262,6.9L208.3,28.882z" />
              <path
                d="M262.886,80.568c-3.186-5.457-6.795-10.748-10.733-15.714l-7.462,5.914c3.662,4.619,7.014,9.529,9.971,14.595
				L262.886,80.568z"
              />
              <path d="M239.29,50.815c-4.605-4.357-9.552-8.419-14.714-12.067l-5.5,7.776c4.79,3.39,9.39,7.162,13.667,11.21L239.29,50.815z" />
              <path
                d="M267.876,118.053l9.248-2.262c-1.505-6.157-3.476-12.252-5.852-18.133l-8.829,3.571
				C264.652,106.687,266.481,112.349,267.876,118.053z"
              />
              <path
                d="M280.319,134.558l-9.476,0.919c0.386,4.019,0.586,8.1,0.586,12.143c0,1.857-0.038,3.7-0.119,5.538l9.514,0.419
				c0.086-1.976,0.129-3.962,0.129-5.957C280.952,143.272,280.743,138.877,280.319,134.558z"
              />
              <path
                d="M264.767,187.801l9.01,3.086c2.057-5.99,3.69-12.186,4.867-18.405l-9.362-1.767
				C268.19,176.491,266.671,182.239,264.767,187.801z"
              />
              <path
                d="M248.671,219.172l7.771,5.505c3.671-5.176,6.995-10.648,9.876-16.267l-8.471-4.352
				C255.167,209.282,252.081,214.368,248.671,219.172z"
              />
              <path
                d="M174.533,268.491l2.067,9.3c6.348-1.414,12.624-3.3,18.662-5.619l-3.414-8.89
				C186.248,265.43,180.424,267.182,174.533,268.491z"
              />
              <path
                d="M147.614,271.43c-3.029-0.071-6.048-0.105-9.067-0.324l-0.695,9.495c3.19,0.238,6.414,0.352,9.581,0.352
				c3.448,0.062,6.643-0.11,9.89-0.348l-0.686-9.495C153.676,271.325,150.638,271.43,147.614,271.43z"
              />
              <path
                d="M99.924,272.153c6.067,2.329,12.343,4.219,18.657,5.619l2.062-9.3c-5.857-1.295-11.681-3.052-17.305-5.21L99.924,272.153
				z"
              />
              <path
                d="M107.681,30.415l-3.071-9.014c-5.995,2.038-11.9,4.533-17.543,7.419l4.333,8.481
				C96.638,34.625,102.114,32.311,107.681,30.415z"
              />
              <path
                d="M50.633,70.672l-7.457-5.924c-3.948,4.971-7.567,10.257-10.748,15.705l8.224,4.81
				C43.605,80.196,46.967,75.291,50.633,70.672z"
              />
              <path d="M28.857,208.287c2.881,5.629,6.2,11.1,9.862,16.281l7.776-5.505c-3.405-4.805-6.486-9.89-9.157-15.119L28.857,208.287z" />
              <path
                d="M25.933,170.582l-9.362,1.757c1.171,6.233,2.8,12.424,4.848,18.414l9.014-3.076
				C28.529,182.12,27.014,176.368,25.933,170.582z"
              />
              <path
                d="M23.81,147.615c0-4.09,0.2-8.229,0.6-12.281l-9.476-0.938c-0.433,4.371-0.648,8.819-0.648,13.224
				c0,1.943,0.043,3.876,0.124,5.81l9.514-0.419C23.848,151.22,23.81,149.425,23.81,147.615z"
              />
              <path
                d="M76.271,46.444l-5.495-7.776c-5.162,3.643-10.119,7.695-14.729,12.048l6.538,6.929
				C66.871,53.601,71.476,49.834,76.271,46.444z"
              />
              <path
                d="M32.843,101.106l-8.824-3.581c-2.376,5.857-4.352,11.952-5.871,18.11l9.243,2.281
				C28.805,112.206,30.638,106.549,32.843,101.106z"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Arch;
